<template>
  <div class="main-view">
    <div class="section">
      <div class="filter__wrap">
        <el-form ref="elFormDom" inline :model="table.params" size="small">
          <el-form-item prop="company_id">
            <el-select v-model="table.params.company_id" placeholder="所属机构" filterable @change="onSearch">
              <el-option v-for="item in companyOpt" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click='showDetail(row)'>收益明细</el-button>
      </template>
    </VTable>

    <div class="my-drawer shadow">
      <el-drawer
        style="position: absolute"
        title=""
        :visible.sync="drawerTable"
        direction="rtl"
        size="80%"
        :modal="false"
        :append-to-body="false">
        <template #title>
          <div class="el-dialog-title">收益明细({{drawer.total}}条)</div>
        </template>
        <el-date-picker
          v-model="drawer.params.selTime"
          style="margin-left:20px"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="核销开始日期"
          end-placeholder="核销结束日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="getIncome"
          size="mini">
            <i slot="suffix" class="el-input__icon el-icon-date"></i>
        </el-date-picker>
        
        <el-table :data="drawer.data" tooltip-effect="dark myTooltipsDrawer">
          <el-table-column type="index" label="ID"></el-table-column>
          <el-table-column property="order_no" label="订单编号" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="goods_title" label="产品名称" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="order_amount" label="订单金额"  width="120">
            <template v-slot="{row}">
              <span>¥{{row.order_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column property="rate" label="抽佣比例"  width="100">
            <template v-slot="{row}">
              <span>{{row.rate}}%</span>
            </template>
          </el-table-column>
          <el-table-column property="company_amount" label="机构收益" width="120">
            <template v-slot="{row}">
              <span>¥{{row.company_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column property="platform_amount" label="平台收益" width="120">
            <template v-slot="{row}">
              <span>¥{{row.platform_amount}}</span>
            </template>
          </el-table-column>
          <el-table-column property="verify_time" label="核销时间" width="140"></el-table-column>
        </el-table>
        <div class='pagination__wrap' >
          <div v-if="drawer.total > 0 && drawer.total < 11">共 {{drawer.total}} 条</div>
          <el-pagination
            v-if="drawer.total > 10"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="drawer.page"
            :page-size="drawer.count"
            :total="drawer.total"
            :page-sizes="[10, 20, 50, 100, 150]"
            @size-change="handleSizeChangeDrawer"
            @current-change="handleCurrentChangeDrawer"
            background
          >
          </el-pagination>
        </div>
      </el-drawer>
    </div>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ProfitList',
  components: {
    VTable,
    // Detail
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters({
      companyOpt: 'companyArr'
    })
  },
  data() {
    return {
      field: [
        { name: "company_name", label: "所属机构", hidden: false },
        { name: "industry", label: "行业类型", hidden: false },
        { name: "total_amount", label: "总销售额/元", hidden: false},
        { name: "company_amount", label: "机构收益/元", hidden: false},
        { name: "income_amount", label: "抽佣金额/元", width:"120", hidden: false},
        { name: "create_time", label: "更新时间", width:"120", hidden: false},
        { name: "action", label: "操作", width:"120", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          company_id: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      drawerTable: false,
      company_id: '',
      drawer: {
        params: {
          selTime:[],
          start_time: '',
          end_time: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/admin/income/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showDetail(row) {
      this.drawerTable = true;
      this.company_id = row.company_id;
      this.getIncome();
    },
    // 收益明细
    getIncome() {
      let _time = this.drawer.params.selTime;
      let _params = {
        company_id: this.company_id,
        page: this.drawer.params.page,
        count: this.drawer.params.count,
        start_time: !!_time ? _time[0] : '',
        end_time: !!_time ? _time[1] : '',
      }
      this.$http.get('/admin/income/incomeList', {params: _params}).then(res => {
        if(res.code === 1) {
          this.drawer.data = res.data.list;
          this.drawer.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // pageSize 改变时会触发
    handleSizeChangeDrawer(pageSize) {
      this.drawer.params.count = pageSize;
      this.drawer.params.page = 1;
      this.getIncome();
    },
    //页面改变时触发
    handleCurrentChangeDrawer(page) {
      this.drawer.params.page = page;
      this.getIncome();
    },
  }
}
</script>

<style scoped lang="scss">
.radio_group_wrap {
  margin:0 18px 18px;

  &::v-deep {
    .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      color: #0376E7;
      background-color: #E5F2FE;
      border-color: #0376E7;
    }
  }
}
.pagination__wrap {
  text-align: right;
  padding: 0 50px;
  // margin: 15px 0;
  color: #606266;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
</style>
